import { profile as api } from "@/api/entities";
import { HeadingV2 } from "@/design-system/components/text/HeadingV2";
import { Box, Flex, Section, Separator } from "@radix-ui/themes";
import { useTranslations } from "next-intl";
import styles from "./ProfileRecommendations.module.scss";
import { ProfileCard } from "../ProfileCard/ProfileCard";
import {
  LeftArrowButton,
  RightArrowButton,
} from "@/design-system/components/ArrowButtons/ArrowButtons";
import classNames from "classnames";
import useScroller from "@/hooks/useScroller";

function ProfileRecommendations({ profile }: { profile: api.Profile }) {
  const t = useTranslations("Profile");
  const hasRecommendedProfiles =
    profile.recommended_profiles && profile.recommended_profiles.length > 0;
  const { scrollerRef, canScrollLeft, canScrollRight, scroll } = useScroller();

  return (
    profile.has_limited_profile &&
    hasRecommendedProfiles && (
      <Box px={{ initial: "5", sm: "0" }}>
        <Separator
          orientation={"horizontal"}
          style={{ backgroundColor: "var(--colorV2-grey-medium-dark)" }}
          size={"4"}
          mt={{ initial: "6", sm: "8" }}
        />

        <Section size={"1"} p={"0"}>
          <Box>
            <HeadingV2
              as={"h2"}
              textStyle={"Headline M"}
              weightStyle={"medium"}
              my={"5"}
            >
              {t("other_recommendations")}
            </HeadingV2>

            <Box pb={"8"}>
              <Flex direction={"column"} gap={"4"} width={"100%"}>
                <Box className={styles.carouselContainer}>
                  <div
                    className={classNames(styles.carouselPreviousButton, {
                      [styles.isHidden]: !canScrollLeft,
                    })}
                  >
                    <LeftArrowButton
                      onClick={() => scroll("left")}
                      disabled={!canScrollLeft}
                      desktopOnly
                    />
                  </div>
                  <div
                    className={classNames(styles.carouselNextButton, {
                      [styles.isHidden]: !canScrollRight,
                    })}
                  >
                    <RightArrowButton
                      onClick={() => scroll("right")}
                      disabled={!canScrollRight}
                      desktopOnly
                    />
                  </div>
                  <Flex
                    ref={scrollerRef}
                    className={styles.carouselPictures}
                    justify={"start"}
                    style={{ overflowX: "scroll", width: "100%" }}
                  >
                    {profile.recommended_profiles &&
                      profile.recommended_profiles.map(
                        (recommendedProfile, index) => (
                          <div key={index}>
                            <ProfileCard profile={recommendedProfile} />
                          </div>
                        ),
                      )}
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Section>
      </Box>
    )
  );
}

export { ProfileRecommendations };
